import { render, staticRenderFns } from "./packageResources.vue?vue&type=template&id=8cb8c8e8&scoped=true&"
import script from "./packageResources.vue?vue&type=script&lang=js&"
export * from "./packageResources.vue?vue&type=script&lang=js&"
import style0 from "./packageResources.vue?vue&type=style&index=0&id=8cb8c8e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cb8c8e8",
  null
  
)

export default component.exports