<template>
  <div class="package-resources-bg">
    <b-container fluid>
      <h2 class="page-title">我的VIP特权</h2>
      <div class="package-resources-con">
        <table>
          <thead>
            <tr>
              <th>资源项</th>
              <th>资源总量</th>
              <th>已用资源量</th>
              <th>剩余资源量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in userResources" :key="index">
              <td>
                <div class="item">{{item.resourceName}}</div>
              </td>
              <td>
                <div class="item">{{item.resourceQuantity}}</div>
              </td>
              <td>
                <div class="item">{{item.resourceUsed}}</div>
              </td>
              <td>
                <div class="item">{{item.resourceRemain}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-container>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        usable: [],
        token: null
      }
    },
    created() {
      this.init();
    },
    computed: {
      userResources(){
        const resourcesArr = [];
        this.usable.forEach(item => {
          if(item.calculateFlag == "0"){
            item.resourceQuantity = item.resourceUsed = "-";
            item.resourceRemain = item.resourceDescribe;
          }
          resourcesArr.push(item);
        })
        return resourcesArr;
      }
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getUsable();
      },
      getUsable(){
        this.$api.getUsable({
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.usable = res.data;
          }
        }).catch(err => console.error(err))
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/packageResources/packageResources";
</style>
